<template>
  <div class="about" v-show="isOut">
    <!-- v-bind:menu_data="menu_data" -->

    <div class="aft_banner">
      <img :src="$host + banner.litpic" alt="" />
      <!-- <img :src="$host + banner.litpicm" alt="" /> -->
    </div>
    <div class="selectToggle">
      <div class="box flexCenter m160">
        <p
          v-for="(item, index) in select_nav"
          :key="index"
          :id="index"
          :class="{ active: select_index == index }"
        >
          <a @click="select(index)"> {{ item.typename }}</a>
        </p>
      </div>
    </div>
    <div class="maodian">
      <div id="select0" class="selectM"></div>
      <div class="aboutBox" id="">
        <div class="aft_title p80">
          <div class="box wow slideInUp">
            <p>{{ aboutInfo.englist_name }}</p>
            <div class="">{{ aboutInfo.typename }}</div>
          </div>
        </div>
        <div class="box m160">
          <div class="boxTop flexBetween">
            <div class="boxLeft wow slideInUp">
              <p><img :src="$host + aboutInfo.img" alt="" /></p>
            </div>
            <div class="boxRight">
              <div class="content1 wow slideInUp">
                {{ aboutInfo.title }}
              </div>
              <div class="content2 wow slideInUp">
                {{ aboutInfo.tdesc }}
              </div>
              <div class="content3 flexBetween wow slideInUp">
                <div class="item">
                  <div class="top">
                    <i-count-up
                      class="counter-value"
                      :endVal="hyjy"
                      :delay="0"
                      :options="options"
                    ></i-count-up
                    >+
                  </div>
                  <div class="bot">年行业经验</div>
                </div>
                <div class="item">
                  <div class="top">
                    <i-count-up
                      class="counter-value"
                      :endVal="cjcj"
                      :delay="0"
                      :options="options"
                    ></i-count-up>
                    <font>万</font>
                  </div>
                  <div class="bot">级纯净车间</div>
                </div>
                <div class="item">
                  <div class="top">
                    <i-count-up
                      class="counter-value"
                      :endVal="scjd"
                      :delay="0"
                      :options="options"
                    ></i-count-up
                    >+<sub>㎡</sub>
                  </div>
                  <div class="bot">生产基地</div>
                </div>
                <div class="item">
                  <div class="top">
                    <i-count-up
                      class="counter-value"
                      :endVal="yftd"
                      :delay="0"
                      :options="options"
                    ></i-count-up
                    >+
                  </div>
                  <div class="bot">研发团队</div>
                </div>
              </div>
            </div>
          </div>
          <div class="boxBot">
            {{ aboutInfo.bdesc }}
          </div>
        </div>
      </div>
      <div id="select1" class="selectM"></div>

      <div class="aboutBox1" id="">
        <div class="aft_title p80">
          <div class="box">
            <p>{{ syll_info.englist_name }}</p>
            <div>{{ syll_info.typename }}</div>
          </div>
        </div>
        <div class="box m160 flexBetween">
          <div class="item" v-for="(item, index) in syll.list" :key="index">
            <div class="content1">
              <p class="img1">
                <img :src="$host + item.icong" alt="" />
              </p>
              <p class="img2">
                <img :src="$host + item.iconw" alt="" />
              </p>
            </div>
            <div class="content2">{{ item.title }}</div>
            <div class="content3">{{ item.desc }}</div>
          </div>
        </div>
      </div>
      <div id="select2" class="selectM"></div>
      <div class="aboutBox2" id="">
        <div class="aft_title p80">
          <div class="box">
            <p>{{ ceo_info.englist_name }}</p>
            <div>{{ ceo_info.typename }}</div>
          </div>
        </div>
        <div class="box flexBetween m160">
          <div class="boxLeft">
            <img :src="$host + ceo_info.img" alt="" />
          </div>
          <div class="boxRight">
            <div class="content1">
              <p>
                {{ ceo_info.title }}
              </p>
              <img src="../assets/images/dou.png" alt="" />
            </div>
            <div class="content2">
              {{ ceo_info.desc }}
            </div>
            <div class="content3">
              <div><span></span> <font style="opacity: 0">袁总</font></div>
              <p>CEO 首席执行官</p>
            </div>
          </div>
        </div>
      </div>
      <div id="select3" class="selectM"></div>
      <div class="aboutBox3" id="">
        <div class="aft_title p80">
          <div class="box">
            <p>{{ shzr_info.englist_name }}</p>
            <div>{{ shzr_info.typename }}</div>
          </div>
        </div>
        <div class="box">
          <div class="content1">人无信不立 业无信不兴</div>
          <div class="content2">
            <div>{{ shzr_info.pro_desc }}</div>
          </div>
        </div>
        <div class="box1">
          <div class="banner m160">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in bannerList"
                  :key="index"
                >
                  <div class="item">
                    <a :href="item.link" class="flexBetween">
                      <div class="left">
                        <p>
                          <img :src="$host + item.img" alt="" />
                        </p>
                      </div>
                      <div class="right">
                        <div>
                          <div class="top">
                            {{ item.title }}
                          </div>
                          <div class="bot">
                            {{ item.desc }}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiperPage flexCenter">
            <div class="prev button">
              <svg
                t="1655284142230"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2370"
                data-spm-anchor-id="a313x.7781069.0.i6"
                width="14"
                height="14"
              >
                <path
                  d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                  p-id="2371"
                  fill="#ffffff"
                ></path>
              </svg>
            </div>
            <p></p>
            <div class="next button">
              <svg
                t="1655284142230"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2370"
                data-spm-anchor-id="a313x.7781069.0.i6"
                width="14"
                height="14"
              >
                <path
                  d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                  p-id="2371"
                  fill="#ffffff"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div id="select4" class="selectM"></div>
      <div class="aboutBox4" id="">
        <div class="aft_title p80">
          <div class="box">
            <p>{{ syly_info.englist_name }}</p>
            <div>{{ syly_info.typename }}</div>
          </div>
        </div>
        <div class="box m160">
          <div class="content1">
            <div>{{ syly_info.pro_desc }}</div>
          </div>
          <div class="content2 flexBetween">
            <div class="item" v-for="(item, index) in syly.list" :key="index">
              <div class="top">
                <img :src="$host + item.img" alt="" />
              </div>
              <div class="bot">
                <div class="con1">{{ item.title }}</div>
                <div class="con2">{{ item.jdesc }}</div>
                <div class="con3">
                  {{ item.desc }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="select5" class="selectM"></div>
      <div class="aboutBox5" id="">
        <div class="aft_title p80">
          <div class="box">
            <p>{{ ryzz_info.englist_name }}</p>
            <div>{{ ryzz_info.typename }}</div>
          </div>
        </div>
        <div class="box m160">
          <div class="toggle flexCenter">
            <p
              v-for="(item, index) in ryzz.list"
              :class="{ active: currentIndex == index }"
              :key="index"
              @click="changeHandler(index)"
            >
              {{ item.typename }}
            </p>
          </div>

          <section class="bannerTwo" style="display: none"></section>

          <!-- :class="{ active: currentIndex == 0 }" -->
          <div class="banner active" ref="bannerBox3">
            <div
              class="div div1"
              :class="{ active: currentIndex == 0 }"
              ref="bannerBox1"
            >
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="(item, index) in ryzz_banner"
                    :key="index"
                  >
                    <div class="item">
                      <div class="content1" :data-bimg="$host + item.bimg">
                        <img
                          :src="$host + item.img"
                          alt=""
                          :data-bimg="$host + item.bimg"
                        />
                      </div>
                      <div class="content2">{{ item.title }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="button">
                <p class="prev">
                  <svg
                    t="1655284142230"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="2370"
                    data-spm-anchor-id="a313x.7781069.0.i6"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                      p-id="2371"
                      fill="#ffffff"
                    ></path>
                  </svg>
                </p>
                <p class="next">
                  <svg
                    t="1655284142230"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="2370"
                    data-spm-anchor-id="a313x.7781069.0.i6"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                      p-id="2371"
                      fill="#ffffff"
                    ></path>
                  </svg>
                </p>
              </div>
            </div>
            <div
              class="div div2"
              :class="{ active: currentIndex == 1 }"
              ref="bannerBox2"
            >
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="(item, index) in ryzz_banne1r"
                    :key="index"
                  >
                    <div class="item">
                      <div class="content1" :data-bimg="$host + item.bimg">
                        <img
                          :src="$host + item.img"
                          alt=""
                          :data-bimg="$host + item.bimg"
                        />
                      </div>
                      <div class="content2">{{ item.title }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="button">
                <p class="prev">
                  <svg
                    t="1655284142230"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="2370"
                    data-spm-anchor-id="a313x.7781069.0.i6"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                      p-id="2371"
                      fill="#ffffff"
                    ></path>
                  </svg>
                </p>
                <p class="next">
                  <svg
                    t="1655284142230"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="2370"
                    data-spm-anchor-id="a313x.7781069.0.i6"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                      p-id="2371"
                      fill="#ffffff"
                    ></path>
                  </svg>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bImg" v-show="imgShow">
      <div class="box">
        <p>
          <svg
            t="1656668878762"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3001"
            width="26"
            height="26"
            @click="closeBimg"
          >
            <path
              d="M544.448 499.2l284.576-284.576a32 32 0 0 0-45.248-45.248L499.2 453.952 214.624 169.376a32 32 0 0 0-45.248 45.248l284.576 284.576-284.576 284.576a32 32 0 0 0 45.248 45.248l284.576-284.576 284.576 284.576a31.904 31.904 0 0 0 45.248 0 32 32 0 0 0 0-45.248L544.448 499.2z"
              p-id="3002"
              fill="#ffffff"
            ></path>
          </svg>
          <img :src="imgSrc" alt="" />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import ICountUp from "vue-countup-v2";
import Swiper from "swiper";
// import router from "../router";
// import { kill } from "process";
// import { type } from "os";

export default {
  name: "contact",
  components: {
    ICountUp,
  },
  data() {
    return {
      // current: 0,
      imgSrc: "",
      imgShow: false,
      isOut: false,
      // 消失
      isHeader: true,
      menu_data: [],
      header_data: {},
      select_nav: {},
      select_index: 0,
      currentIndex: 0,
      hyjy: 0,
      cjcj: 0,
      scjd: 0,
      yftd: 0,
      options: {
        duration: 2,
        useEasing: false,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      // 企业介绍
      aboutInfo: {
        title: "",
      },
      // banner图
      banner: {},
      //  事业理论
      syll: {},
      syll_info: {},
      // CEO致辞
      ceo_info: {},
      // 社会责任
      shzr: {},
      shzr_info: {},
      bannerList: [],
      // 事业领域
      syly: {},
      syly_info: {},
      // 荣誉资质
      ryzz: {},
      ryzz_info: {},
      ryzz_banner: [],
      ryzz_banne1r: [],
      bannerHtml: "",
    };
  },
  mounted() {
    this.$bus.on("productTops4", (value) => {
      this.$nextTick(() => {
        this.$el.querySelector("#select" + value).scrollIntoView();
      });
    });
    this.$nextTick(() => {
      new this.$wow.WOW().init();
    });
    this.$http.get("api.php/Webinfo/about").then(
      function (res) {
        this.menu_data = JSON.parse(res.data).menu;
        // 导航

        const banner = JSON.parse(res.data).banner;
        this.banner = banner;
        // banner

        this.select_nav = JSON.parse(res.data).bbanner_cate;
        // 二级栏目

        const about_info = JSON.parse(res.data).about_info;
        this.aboutInfo = about_info;
        this.hyjy = parseInt(about_info.hyjy);
        this.cjcj = parseInt(about_info.cjcj);
        this.scjd = parseInt(about_info.scjd);
        this.yftd = parseInt(about_info.yftd);
        // 企业介绍

        const syll_ = JSON.parse(res.data);
        const syll = syll_.syll;
        this.syll = syll;
        this.syll_info = syll.cate;
        // 事业理论

        const ceo_info = JSON.parse(res.data).ceo_info;
        this.ceo_info = ceo_info;
        // CEO致辞

        const shzr_ = JSON.parse(res.data);
        const shzr = shzr_.shzr;
        this.shzr = shzr;
        this.shzr_info = shzr.cate;
        this.bannerList = shzr.list;
        // 社会责任

        const syly_ = JSON.parse(res.data);
        const syly = syly_.syly;
        this.syly = syly;
        this.syly_info = syly.cate;
        // 事业领域

        const ryzz_ = JSON.parse(res.data);
        const ryzz = ryzz_.ryzz;
        this.ryzz = ryzz;
        this.ryzz_info = ryzz.cate;
        this.ryzz_banner = ryzz.list[0].list.data;
        this.ryzz_banne1r = ryzz.list[1].list.data;

        // 荣誉资质
        this.isOut = true;

        // this.honorBanner();

        // console.log(JSON.parse(res.data));

        const idM = this.$route.query.id;
        if (idM != undefined) {
          this.$nextTick(() => {
            this.$el.querySelector("#select" + idM).scrollIntoView();
          });
        }
      },
      function (res) {
        console.log(res.status);
      }
    );
  },
  watch: {
    $route(to, from) {
      if (to.name == "About") {
        const idM = to.query.id;
        this.select_index = idM;
        if (idM != undefined) {
          const pageClick = this.$el.querySelector("#select" + idM);

          window.scrollTo({
            top: pageClick.offsetTop,
          });
        }
      }
    },
    // $route: {
    //   handler(newRouter) {
    //     if (newRouter.name == "About") {
    //       const idM = newRouter.query.id;
    //       // const PageId = document.querySelector("#select" + idM);

    //       console.log(idM);
    //       this.$el.querySelector("#select" + idM).scrollIntoView();

    //       // window.scrollTo({
    //       //   top: PageId.offsetTop - 70,
    //       //   behavior: "smooth",
    //       // });
    //     }
    //   },
    //   immediate: true,
    // },
    bannerList: {
      handler() {
        //现在通过watch监听bannerList属性的属性值得变化
        //如果执行handler方法：代表组件实例身上这个属性已经有了【数组：四个元素】
        //当前这个函数执行：只能保证bannerList数据已经有了，但是无法保证v-for已经执行结束了
        //v-for执行完毕，才有结构【你现在watch当中没办法保证】
        //nextTick:在下次DOM更新循环结束之后执行延迟回调，在修改数据之后立即使用这个方法，获取更新后的DOM
        this.$nextTick(() => {
          //当执行这个回调的时候：保证服务器数据回来了，v-for执行完毕了
          const shzrBanner = new Swiper(".aboutBox3 .box1 .swiper-container", {
            loop: true,
            autoplay: {
              delay: 5000,
              disableOnInteraction: false,
            },
            spaceBetween: 100,
            slidesPerView: 2,
            speed: 800,
            navigation: {
              prevEl: ".aboutBox3 .box1 .prev",
              nextEl: ".aboutBox3 .box1 .next",
            },
            pagination: {
              el: ".aboutBox3 .box1 .swiperPage p",
              clickable: true,
            },

            breakpoints: {
              320: {
                spaceBetween: 0,
                slidesPerView: 1,
              },
              1366: {
                spaceBetween: 60,
                slidesPerView: 2,
              },
              1600: {
                spaceBetween: 100,
                slidesPerView: 2,
              },
            },
          });

          shzrBanner.slideNext();
        });
      },
    },
    ryzz_banner: {
      handler() {
        //现在通过watch监听bannerList属性的属性值得变化
        //如果执行handler方法：代表组件实例身上这个属性已经有了【数组：四个元素】
        //当前这个函数执行：只能保证bannerList数据已经有了，但是无法保证v-for已经执行结束了
        //v-for执行完毕，才有结构【你现在watch当中没办法保证】
        //nextTick:在下次DOM更新循环结束之后执行延迟回调，在修改数据之后立即使用这个方法，获取更新后的DOM
        const that = this;
        this.$nextTick(() => {
          // this.$refs.bannerBox3.appendChild(this.$refs.bannerBox1);
          //当执行这个回调的时候：保证服务器数据回来了，v-for执行完毕了

          const bannerDiv1 = new Swiper(
            ".aboutBox5 .banner .div1 .swiper-container",
            {
              loop: true,
              autoplay: {
                delay: 2000,
                disableOnInteraction: false,
              },
              centeredSlides: true,
              spaceBetween: 30,
              slidesPerView: 3,
              observer: true,
              observeParents: true,
              speed: 800,
              navigation: {
                prevEl: ".aboutBox5 .banner .div1 .prev",
                nextEl: ".aboutBox5 .banner .div1 .next",
              },
              breakpoints: {
                320: {
                  spaceBetween: 0,
                  slidesPerView: 1,
                },
                768: {
                  spaceBetween: 30,
                  slidesPerView: 3,
                  centeredSlides: true,
                },
                1366: {
                  spaceBetween: 30,
                  slidesPerView: 3,
                  centeredSlides: true,
                },
                1600: {
                  spaceBetween: 30,
                  slidesPerView: 3,
                  centeredSlides: true,
                },
              },
              on: {
                click: function (e) {
                  if (e.target.dataset.bimg) {
                    that.imgSrc = e.target.dataset.bimg;
                    that.imgShow = true;
                  }
                },
              },
            }
          );
          bannerDiv1.slideNext();

          const bannerDiv2 = new Swiper(
            ".aboutBox5 .banner .div2 .swiper-container",
            {
              loop: true,
              autoplay: {
                delay: 2000,
                disableOnInteraction: false,
              },
              centeredSlides: true,
              spaceBetween: 30,
              slidesPerView: 3,
              observer: true,
              observeParents: true,
              speed: 800,
              navigation: {
                prevEl: ".aboutBox5 .banner .div2 .prev",
                nextEl: ".aboutBox5 .banner .div2 .next",
              },
              breakpoints: {
                320: {
                  spaceBetween: 0,
                  slidesPerView: 1,
                },
                768: {
                  spaceBetween: 30,
                  slidesPerView: 3,
                  centeredSlides: true,
                },
                1366: {
                  spaceBetween: 30,
                  slidesPerView: 3,
                  centeredSlides: true,
                },
                1600: {
                  spaceBetween: 30,
                  slidesPerView: 3,
                  centeredSlides: true,
                },
              },
              on: {
                click: function (e) {
                  if (e.target.dataset.bimg) {
                    that.imgSrc = e.target.dataset.bimg;
                    that.imgShow = true;
                  }
                },
              },
            }
          );
          bannerDiv2.slideNext();
        });
      },
    },
  },
  methods: {
    changeHandler(i) {
      this.currentIndex = i;
      // this.$nextTick(() => {
      //   const divDome = this.$refs.bannerBox3;
      //   const divDomeC = divDome.lastElementChild;
      //   divDome.removeChild(divDomeC);
      //   if (i == 0) {
      //     this.$refs.bannerBox3.appendChild(this.$refs.bannerBox1);
      //   } else if (i == 1) {
      //     this.$refs.bannerBox3.appendChild(this.$refs.bannerBox2);
      //   }
      //
      //   this.honorBanner();
      // });
    },
    select(key) {
      this.select_index = key;

      const PageId = document.querySelector("#select" + key);
      window.scrollTo({
        top: PageId.offsetTop,
        behavior: "smooth",
      });
    },

    closeBimg() {
      this.imgSrc = " ";
      this.imgShow = false;
    },
    honorBanner() {
      var that = this;

      new Swiper(".aboutBox3 .box1 .swiper-container", {
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        spaceBetween: 100,
        slidesPerView: 2,
        speed: 800,
        navigation: {
          prevEl: ".aboutBox3 .box1 .prev",
          nextEl: ".aboutBox3 .box1 .next",
        },
        pagination: {
          el: ".aboutBox3 .box1 .swiperPage p",
          clickable: true,
        },

        breakpoints: {
          320: {
            spaceBetween: 0,
            slidesPerView: 1,
          },
          1366: {
            spaceBetween: 60,
            slidesPerView: 2,
          },
          1600: {
            spaceBetween: 100,
            slidesPerView: 2,
          },
        },
      });

      // honorSwiper.update();
    },
  },
};
</script>
<style lang="less">
@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;
.aft_banner {
  padding-bottom: 33.04%;
  position: relative;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
.bImg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: rgba(0, 0, 0, 0.5);
  .box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    p {
      position: relative;
      margin: auto;
      img {
        // width: 100%;
        max-width: 80vw;
        max-height: 80vh;
      }
      svg {
        position: absolute;
        top: -0.3rem;
        right: -0.3rem;
        cursor: pointer;
      }
    }
  }
}
.aboutBox {
  & > .box {
    .boxTop {
      margin-top: 0.64rem;
      .boxLeft {
        width: 50%;
        p {
          overflow: hidden;
          img {
            width: 100%;
            transition: 0.4s;
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
      .boxRight {
        width: 45%;
        .content1 {
          font-size: 0.24rem;
          font-family: @albb_b;
          line-height: 0.42rem;
          padding: 0.32rem 0;
        }
        .content2 {
          font-size: 0.24rem;
          color: #666;
          line-height: 0.42rem;
        }
        .content3 {
          margin-top: 0.54rem;
          .item {
            .top {
              font-size: 0.56rem;
              color: @color;
              span {
                font-family: @gilroy_b;
              }
              font {
                font-size: 0.32rem;
              }
              sub {
                font-size: 0.22rem;
                position: relative;
                top: -0.08rem;
              }
            }
            .bot {
              font-family: @albb_m;
              font-size: 0.22rem;
            }
          }
        }
      }
    }
  }
  .boxBot {
    font-size: 0.24rem;
    color: #666;
    line-height: 0.42rem;
    text-align: justify;
    padding: 0.72rem 0 1rem;
  }
}
.aboutBox1 {
  background: #f2f2f2;
  padding-bottom: 0.9rem;
  .aft_title {
    .box {
      p {
        color: #e5e5e5;
      }
    }
  }
  & > .box {
    margin-top: 0.54rem;
    .item {
      width: 32%;
      background: #fff;
      box-sizing: border-box;
      padding: 0.45rem;
      border-radius: 0.05rem;
      transition: 0.4s;
      .content1 {
        img {
          height: 0.67rem;
        }
        .img2 {
          display: none;
        }
      }
      .content2 {
        font-size: 0.26rem;
        font-size: @albb_b;
        margin-top: 0.9rem;
        // transition: 0.4s;
      }
      .content3 {
        font-size: 0.2rem;
        color: #666;
        margin-top: 0.15rem;
        // transition: 0.4s;
      }
      &:hover {
        background: @color;
        .content1 {
          .img1 {
            display: none;
          }
          .img2 {
            display: block;
          }
        }
        .content2 {
          color: #fff;
        }
        .content3 {
          color: #fff;
        }
      }
    }
  }
}
.aboutBox2 {
  padding-bottom: 0.9rem;
  & > .box {
    margin-top: 0.54rem;
    .boxLeft {
      width: 30%;
      img {
        width: 100%;
      }
    }
    .boxRight {
      width: 60%;
      padding-top: 0.45rem;
      .content1 {
        position: relative;
        display: flex;
        justify-content: flex-end;
        p {
          font-size: 0.24rem;
          font-family: @albb_b;
          line-height: 0.42rem;
          text-align: right;
          width: 80%;
        }
        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 1.1rem;
        }
      }
      .content2 {
        font-size: 0.2rem;
        color: #666;
        line-height: 0.42rem;
        text-align: justify;
        margin-top: 0.76rem;
      }
      .content3 {
        text-align: right;
        div {
          font-size: 0.24rem;
          font-family: @albb_b;
          margin: 0.7rem 0 0.05rem;
          span {
            display: inline-block;
            width: 1.7rem;
            height: 1px;
            background: #ddd;
            margin-right: 0.36rem;
            vertical-align: middle;
          }
        }
        p {
          font-size: 0.18rem;
          color: #666;
        }
      }
    }
  }
}
.aboutBox3 {
  background: url(../assets/images/about3.png) no-repeat 50% 50%;
  background-size: cover;
  padding-bottom: 0.9rem;
  .aft_title {
    .box {
      p {
        color: #fff;
        opacity: 0.1;
      }
      div {
        color: #fff;
      }
    }
  }
  & > .box {
    .content1 {
      font-size: 0.36rem;
      font-family: @albb_b;
      color: #fff;
      text-align: center;
      margin: 0.32rem 0;
    }
    .content2 {
      width: 53vw;
      margin: auto;
      font-size: 0.2rem;
      line-height: 0.42rem;
      color: #fff;
      opacity: 0.8;
    }
  }
  & > .box1 {
    margin-top: 0.9rem;
    .banner {
      margin-bottom: 0.8rem;
      .item {
        .left {
          width: 40%;
          p {
            img {
              width: 100%;
            }
          }
        }
        .right {
          width: 56%;
          .top {
            font-size: 0.22rem;
            color: #fff;
            line-height: 0.3rem;
          }
          .bot {
            font-size: 0.18rem;
            line-height: 0.34rem;
            color: #fff;
            margin-top: 0.18rem;
          }
        }
      }
    }
    .swiperPage {
      z-index: 2;
      div {
      }
      .button {
        width: 0.45rem;
        height: 0.45rem;
        display: flex;
        align-items: center;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.5);
        cursor: pointer;
        transition: 0.4s;
        svg {
          margin: auto;
        }
        &:hover {
          border: 1px solid @color;
          svg {
            path {
              fill: @color;
            }
          }
        }
      }
      .prev {
        svg {
          transform: rotate(180deg);
        }
      }
      p {
        line-height: 0.4rem;
        margin: 0 0.45rem;
        span {
          width: 0.2rem;
          height: 2px;
          border-radius: 0;
          background: #fff;
          margin: 0 5px;
          opacity: 1;
        }
        .swiper-pagination-bullet-active {
          background: @color;
        }
      }
    }
  }
}
.aboutBox4 {
  & > .box {
    margin-top: 0.32rem;
    padding-bottom: 0.9rem;
    .content1 {
      width: 53vw;
      margin: auto;
      font-size: 0.2rem;
      line-height: 0.42rem;
      color: #666;
      opacity: 0.8;
    }
    .content2 {
      padding: 0 1rem;
      margin-top: 0.64rem;
      .item {
        width: 47%;
        .top {
          overflow: hidden;
          img {
            width: 100%;
            vertical-align: bottom;
            transition: 1s;
          }
        }
        .bot {
          box-sizing: border-box;
          margin: -0.3rem 0.25rem 0;
          // background: #f5f5f5;
          padding: 0.7rem 0.7rem 0.5rem;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 0.3rem;
            left: 0;
            width: 100%;
            height: 100%;
            transition: 0.4s;
            // background: @color;
          }
          .con1 {
            font-size: 0.26rem;
            font-family: @albb_b;
            text-align: center;
            position: relative;
            z-index: 1;
            transition: 0.4s;
          }
          .con2 {
            font-family: @albb_m;
            font-size: 0.2rem;
            color: @color;
            margin-top: 0.12rem;
            text-align: center;
            position: relative;
            z-index: 1;
            transition: 0.4s;
          }
          .con3 {
            font-size: 0.18rem;
            color: #666;
            line-height: 0.34rem;
            margin-top: 0.5rem;
            position: relative;
            z-index: 1;
            transition: 0.4s;
            text-align: justify;
          }
        }
        &:hover {
          .top {
            img {
              transform: scale(1.1);
            }
          }
          .bot {
            &::after {
              background: @color;
              top: 0;
            }
            .con1 {
              color: #fff;
            }
            .con2 {
              color: #fff;
            }
            .con3 {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.aboutBox5 {
  background: url(../assets/images/about5.png) no-repeat 50% 50%;
  background-size: cover;
  padding-bottom: 0.9rem;
  .aft_title {
    .box {
      p {
        color: #e5e5e5;
      }
    }
  }
  & > .box {
    padding: 0 1rem;
    .toggle {
      margin-top: 0.16rem;
      font-size: 0.22rem;
      color: #333;
      p {
        margin: 0 8px;
        cursor: pointer;
      }
      .active {
        color: @color;
      }
    }
    .banner {
      margin-top: 0.8rem;
      position: relative;
      height: 540px;
      box-sizing: border-box;
      & > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        padding: 0 2rem;
        box-sizing: border-box;
        opacity: 0;
        &.active {
          z-index: 4;
          opacity: 1;
        }
      }
      .div2 {
        // display: none;
      }

      // display: none;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background: #ddd;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        bottom: 0.72rem;
      }
      .swiper-slide {
        transform: translate3d(0, 0, 0);
        overflow: hidden;
      }
      .item {
        padding: 0 0.3rem;
        position: relative;
        cursor: pointer;
        &::after {
          content: "";
          position: absolute;
          width: 0%;
          height: 2px;
          background: @color;
          transition: 1.5s;
          content: "";
          left: 50%;
          transform: translateX(-50%);
          bottom: 0.72rem;
        }
        .content1 {
          img {
            width: 100%;
          }
        }
        .content2 {
          margin-top: 1.3rem;
          font-family: @albb_m;
          font-size: 0.24rem;
          text-align: center;
          opacity: 0;
          transition: 0.4s;
          height: 0.6rem;
          line-height: 0.3rem;
        }
      }
      .swiper-slide {
        transform: scale(1);
      }
      .swiper-slide-active,
      .swiper-slide-duplicate-active {
        transform: scale(1);
        .item {
          &::after {
            width: 100%;
          }
        }
        .content2 {
          opacity: 1;
        }
      }

      .button {
        p {
          position: absolute;
          top: 36%;
          z-index: 2;
          cursor: pointer;
          pointer-events: auto;
          svg {
            pointer-events: auto;
          }
          path {
            fill: #999;
            transition: 0.4s;
          }
          &:hover {
            path {
              fill: @color;
            }
          }
        }
        .prev {
          transform: rotate(180deg);
          left: 0;
        }
        .next {
          right: 0;
        }
      }
    }
  }
}
@media screen and(max-width:1500px) {
  .aboutBox > .box .boxTop .boxRight .content3 .item .top {
    font-size: 0.4rem;
  }
  .aboutBox5 > .box .banner .item .content2 {
    font-size: 0.2rem;
  }
}

@media screen and(max-width:768px) {
  .aboutBox2 > .box .boxRight .content1 {
    width: 100%;
    justify-content: flex-end;
  }
  .aboutBox2 > .box .boxRight .content2 {
    width: 100%;
  }
  .aboutBox4 > .box .content2 .item:hover .bot::after {
  }
  .aboutBox4 > .box .content2 .item .bot .con3 {
    padding: 0 0.2rem;
  }
  .selectToggle .box {
    padding: 0.1rem 0;
  }
  .selectToggle .box p {
    line-height: 0.6rem;
  }
  .aft_title .box p {
    font-size: 0.6rem;
  }
  .aft_title .box div {
    font-size: 0.36rem;
  }
  .aboutBox > .box .boxTop .boxLeft {
    width: 100%;
  }
  .aboutBox > .box .boxTop .boxRight {
    width: 100%;
  }
  .aboutBox > .box .boxTop .boxRight .content3 .item {
    width: 50%;
    margin: 0 0 0.1rem;
  }

  .aboutBox2 > .box .boxLeft {
    width: 100%;
  }
  .aboutBox2 > .box .boxRight {
    width: 100%;
  }
  .aboutBox2 > .box .boxRight .content1 img {
    width: 0.6rem;
  }
  .aboutBox3 > .box .content2 {
    width: 90vw;
  }
  .aboutBox3 > .box .content1 {
    font-size: 0.26rem;
  }
  .aboutBox3 > .box1 .banner .item .left {
    width: 100%;
  }
  .aboutBox3 > .box1 .banner .item .right {
    width: 100%;
    margin-top: 0.3rem;
  }
  .aboutBox4 > .box .content1 {
    width: 90vw;
  }
  .aboutBox4 > .box .content2 {
    padding: 0;
  }
  .aboutBox4 > .box .content2 .item {
    width: 100%;
  }
  .aboutBox4 > .box .content2 .item .bot {
    margin: 0;
    padding: 0.4rem 0 0.6rem;
  }
  .aboutBox5 > .box {
    padding: 0;
  }
  .aboutBox5 > .box .banner {
    padding: 0 0.5rem;
  }
  .aboutBox5 > .box .banner {
    height: 252px;
  }
}
.selectM {
  position: relative;
  top: -74px;
}
@media screen and(max-width:669px) {
  .aboutBox1 > .box .item {
    width: 100%;
    margin-bottom: 0.2rem;
  }
  .aboutBox5 > .box .banner > div {
    padding: 0 1rem;
  }
  .aboutBox5 > .box .banner {
    height: 376px;
  }
  .selectM {
    position: relative;
    top: -60px;
  }
  .aboutBox > .box .boxTop .boxRight .content3 .item .top {
    text-align: center;
  }
  .aboutBox > .box .boxTop .boxRight .content3 .item .bot {
    text-align: center;
  }
}
</style>
